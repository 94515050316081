// extracted by mini-css-extract-plugin
export var accordionContent = "archiveAccordionSingle-module--accordionContent--3858c";
export var accordionContentActive = "archiveAccordionSingle-module--accordionContentActive--99d37";
export var accordionHandle = "archiveAccordionSingle-module--accordionHandle--b9dab";
export var accordionItem = "archiveAccordionSingle-module--accordionItem--5450c";
export var accordionTitle = "archiveAccordionSingle-module--accordionTitle--e2496";
export var accordionTrigger = "archiveAccordionSingle-module--accordionTrigger--0c80c";
export var accordionWrapper = "archiveAccordionSingle-module--accordionWrapper--10f19";
export var focussedTrigger = "archiveAccordionSingle-module--focussedTrigger--0fc95";
export var open = "archiveAccordionSingle-module--open--1b8f5";
export var textAccordionBlock = "archiveAccordionSingle-module--textAccordionBlock--c6819";