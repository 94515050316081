// extracted by mini-css-extract-plugin
export var postLatestCard = "postCard-module--postLatestCard--00876";
export var postLatestCat = "postCard-module--postLatestCat--811ff";
export var postLatestCatAdvice = "postCard-module--postLatestCatAdvice--06f09";
export var postLatestCatBehavior = "postCard-module--postLatestCatBehavior--62635";
export var postLatestCatBehaviour = "postCard-module--postLatestCatBehaviour--ccf41";
export var postLatestCatFun = "postCard-module--postLatestCatFun--e70c2";
export var postLatestCatHealth = "postCard-module--postLatestCatHealth--9f8ef";
export var postLatestDesc = "postCard-module--postLatestDesc--68c3c";
export var postLatestImage = "postCard-module--postLatestImage--19c43";
export var postLatestInfo = "postCard-module--postLatestInfo--bfeaf";
export var postLatestTitle = "postCard-module--postLatestTitle--32673";
export var small = "postCard-module--small--94d06";